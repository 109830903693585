import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MUIDataTable from "mui-datatables";
import { NoStyleLink } from "components/NoStyleLink";
import WarningIcon from "@material-ui/icons/Warning";
import { CustomerCommentsPanel } from "comments/CustomerCommentsPanel";
import { CustomerPlannigCommentsPanel } from "comments/CustomerPlanningCommentsPanel";
import useBudget from "customers/useBudget";
import { useAllEMs } from "ems/useAllEMs";
import { Budget, CustomerHandle, ContractHourType } from "msd-capacity-planning-model";
import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { BurnedBudget } from "../../customers/BurnedBudgetTypes";
import { useBurnedBudget } from "../../customers/useBurnedBudget";
import dayjs from "../../utils/dayjs";
import { CustomerLinkBar } from "../customer/CustomerLinkBar";

const useStyles = makeStyles((theme) => ({
  link: { textDecoration: "inherit", color: "inherit" },
}));

interface RowItem {
  customer: CustomerHandle;
  budget: Budget;
  burned: BurnedBudget;
}

export function CustomersTable({
  year,
  month,
  customers,
  emColumn = true,

  // emColumn and overBudgetColumn are not honoring the true/false values listed here and i'm not sure why
  // will have to investigate this further.

  inBudgetColumn = false,
  overBudgetColumn = false,
  budgetColumn = true,
  consumedBudgetPercentColumn = true,
  retrospectiveNotesColumn = false,
  planningNotesColumn = false,
  linkPrefix = "",
}: {
  year: number;
  month: number;
  customers: { [customerId: string]: CustomerHandle };
  emColumn?: boolean;
  inBudgetColumn?: boolean;
  overBudgetColumn?: boolean;
  budgetColumn?: boolean;
  consumedBudgetPercentColumn?: boolean;
  retrospectiveNotesColumn?: boolean;
  planningNotesColumn?: boolean;
  linkPrefix?: string;
}) {
  const location = useLocation();
  const classes = useStyles();
  const ems = useAllEMs();
  const kickoffMissingText = "kickoff date is missing";
  const burned = useBurnedBudget(year, month);
  const budgets = useBudget(year, month);
  const [items, setItems] = useState([] as RowItem[]);
  const [anchorEl, setAnchorEl] = useState(null as any);
  const [popOverCustomer, setPopOverCustomer] = useState(
    undefined as CustomerHandle | undefined
  );
  const [engagementLengthColumn, setEngagementLengthColumn] = useState(false);

  useEffect(() => {
    const newItems = Object.values(customers)
      .map((customer) => {
        return {
          customer,
          burned: burned.items[customer.id],
          budget: budgets.items[customer.id],
        };
      })
      .sort((i1, i2) => i1.customer.compare(i2.customer));
    setItems(newItems);
  }, [customers, burned, budgets]);

  const EMNames = useMemo(() => Array.from(new Set(Object.values(customers).map(customer => ems[customer.engagementManager]?.firstName + " " + ems[customer.engagementManager]?.lastName))), [customers]);
  const customerNames = useMemo(() => Object.values(customers).map(customer => customer.name), [customers]);
  const burnedBudgetItems = useMemo(() => Array.from(new Set(Object.values(customers).map(customer => Math.round(burned?.items[customer.id]?.getConsumedInBudget())))), [customers]);
  const overBudgetItems = useMemo(() => Array.from(new Set(Object.values(customers).map(customer => Math.round(burned?.items[customer.id]?.getConsumedOverBudget())))), [customers]);

  const engagementLengthItems = useMemo(() => Array.from(new Set(Object.values(customers).map(customer => 
    customer.getEngagementLengthInMs() ? dayjs.duration(customer.getEngagementLengthInMs(), "millisecond").humanize() : kickoffMissingText)))
    , [customers]);
  


  const columns: any = [
    {
      name: "Customer",
      options: {
      filter: true,
      filterOptions:{
        names: customerNames,
        logic(customer: { name: string; }, filters: any) {
          let textString = customer.name;
          return !filters.includes(textString);} 

      },
      sort: true,
      customBodyRender: (customer: CustomerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
        return <NoStyleLink
               pathname={`${location.pathname}${linkPrefix}/${customer.id}`}
               text={customer.name}
               />
      },      
      sortCompare: (order: string) => {
        return (obj1: { data: CustomerHandle }, obj2: { data: CustomerHandle } ) => {
          return obj1.data.compare(obj2.data) * (order === 'asc' ? 1 : -1);
        };
      },

      setCellProps: () => ({ style: { padding: 5 }}),
      setCellHeaderProps: () => ({ style: { padding: 5 }}),
      }},
  {
    name: "",             // placeholder for warning icon
    options: {
    filter: false,
    sort: false,
    viewColumns: false,
    download: false,
    customBodyRender: (customer: CustomerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
      return (
        burned.items[customer.id]?.getConsumedOverBudget() > 0 ? (
        <Tooltip title="Over budget">
          <WarningIcon color="error" style={{ marginLeft: 8 }} />
        </Tooltip>
      ) : null)
    }
  }
},
...(emColumn ? [
  {
      name: "EM",
      options: {
      filter: true,
      filterOptions:{
        names: EMNames,
        logic(customer: {
          engagementManager: any; name: string; }, filters: any) {
          let textString = ems[customer.engagementManager]?.firstName + " " + ems[customer.engagementManager]?.lastName;
          return !filters.includes(textString);} 

      },
      sort: true,
      customBodyRender: (customer: CustomerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
        return <Link
        to={`/dashboards/ems/${customer.engagementManager || ""}`}
        className={classes.link}
      >
        <span>
          {ems[customer.engagementManager]?.firstName}{" "}
          {ems[customer.engagementManager]?.lastName}
        </span>
      </Link>;
      },      
      sortCompare: (order: string) => {
        return (obj1: { data: CustomerHandle }, obj2: { data: CustomerHandle } ) => {
          return obj1.data.compare(obj2.data) * (order === 'asc' ? 1 : -1);
        };
      },
      setCellProps: () => ({ style: { padding: 5 }}),
      setCellHeaderProps: () => ({ style: { padding: 5 }}),
      }
  }
] : []),
...(inBudgetColumn ? [
  {
    name: "Burned Budget",
    options: {
      filter: true,
      filterOptions:{
        names:  burnedBudgetItems,
        logic(customer: { id: string | number; }, filters: any) {
          let textString = Math.round(burned?.items[customer.id]?.getConsumedInBudget());
          return !filters.includes(textString);} 
      },
      sort: true,
      customBodyRender: (customer: CustomerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
        return  Math.round(burned?.items[customer.id]?.getConsumedInBudget())
      },      
      sortCompare: (order: string) => {
        return (obj1: any, obj2: any ) => {
          let val1 = Math.round(burned?.items[obj1.data.id]?.getConsumedInBudget()) || 0
          let val2 = Math.round(burned?.items[obj2.data.id]?.getConsumedInBudget()) || 0 
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
    },
      setCellProps: () => ({ style: { padding: 5 }}),
      setCellHeaderProps: () => ({ style: { padding: 5 }}),
      }}
    ] : []),
      ...( overBudgetColumn ? [  
        {
          name: "Over Budget",
          options: {
          filter: true,
          filterOptions:{
            names: overBudgetItems, 
            logic(customer: { id: string | number; }, filters: any) {
              let textString = Math.round(burned?.items[customer.id]?.getConsumedOverBudget());
              return !filters.includes(textString);} 
          },
          sort: true,
          customBodyRender: (customer: CustomerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
            
            return Math.round(burned?.items[customer.id]?.getConsumedOverBudget())
          },      
          sortCompare: (order: string) => {
            return (obj1: any, obj2: any ) => {
              let val1 = Math.round(burned?.items[obj1.data.id]?.getConsumedOverBudget()) || 0
              let val2 = Math.round(burned?.items[obj2.data.id]?.getConsumedOverBudget()) || 0 
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
        },
          setCellProps: () => ({ style: { padding: 5 }}),
          setCellHeaderProps: () => ({ style: { padding: 5 }}),
          }}
  ] : []),
  ...(budgetColumn ? [
  {
    name: "Budget",
    options: {
    filter: true,
    sort: true,
    customBodyRender: (customer: CustomerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
      return (
        burned?.items[customer.id]?.total ||
        (customer.contractHourTypes?.includes(ContractHourType.TEAM_HOURS) ? budgets?.items[customer.id]?.teamHours: 0) +
        (customer.contractHourTypes?.includes(ContractHourType.OPS_HOURS) ? budgets?.items[customer.id]?.opsHours: 0) +
        (customer.contractHourTypes?.includes(ContractHourType.BANK_HOURS) ? budgets?.items[customer.id]?.bankHours: 0)
      ).toFixed(1)
    },    
    sortCompare: (order: string) => {
      return (obj1: any, obj2: any ) => {
        let val1 = (burned?.items[obj1.data.id]?.total || budgets?.items[obj1.data.id]?.getPlannedHours())
        let val2 = (burned?.items[obj2.data.id]?.total || budgets?.items[obj2.data.id]?.getPlannedHours())
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
  },  
    setCellProps: () => ({ style: { padding: 5 }}),
    setCellHeaderProps: () => ({ style: { padding: 5 }}),
    }},
  ] : []),
    ...(consumedBudgetPercentColumn?[  
  {
    name: "Consumed",
    options: {
    filter: true,
    sort: true,
    customBodyRender: (customer: CustomerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
      return (Math.round(
        ((burned?.items[customer.id]?.harvestConsumedBudget || 0) / burned?.items[customer.id]?.total ||
          0) * 100
      ) + "%");
    },    
    sortCompare: (order: string) => {
      return (obj1: any, obj2: any ) => {
        let val1 = Math.round(((burned?.items[obj1.data.id]?.harvestConsumedBudget || 0) / burned?.items[obj1.data.id]?.total || 0) * 100)
        let val2 = Math.round(((burned?.items[obj2.data.id]?.harvestConsumedBudget || 0) / burned?.items[obj2.data.id]?.total || 0) * 100)
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
  },  
    setCellProps: () => ({ style: { padding: 5 }}),
    setCellHeaderProps: () => ({ style: { padding: 5 }}),
    }}
  ]:[]),
  {
    name: "Engagement Length",
    options: {
    filter: true,
    filterOptions:{
      names: engagementLengthItems,
      logic(text: any, filters: string | any[]) {
        let textString = text instanceof Object ? kickoffMissingText : text
        return !filters.includes(textString);} 
    },
    sort: true,
    customBodyRender: (customer: CustomerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
      return customer.getEngagementLengthInMs() ? (
        dayjs
          .duration(
            customer.getEngagementLengthInMs(),
            "millisecond"
          )
          .humanize()
      ) : (
        <Tooltip title={kickoffMissingText}>
          <WarningIcon style={{ color: "orange" }} />
        </Tooltip>
      );
    },      
    sortCompare: (order: string) => {
      return (obj1: any, obj2: any) => {
        let val1 = obj1.data.getEngagementLengthInMs() ? (
          dayjs
            .duration(
              obj1.data.getEngagementLengthInMs(),
              "millisecond"
            )
        ) : dayjs.duration(0,"millisecond")
        let val2 = obj2.data.getEngagementLengthInMs() ? (
          dayjs
            .duration(
              obj2.data.getEngagementLengthInMs(),
              "millisecond"
            )
        ) : dayjs.duration(0,"millisecond")
        return (val1.asMilliseconds() - val2.asMilliseconds()) * (order === 'asc' ? 1 : -1);
      };
  },  
    setCellProps: () => ({ style: { padding: 5 }}),
    setCellHeaderProps: () => ({ style: { padding: 5 }}),
    }},
  ...(retrospectiveNotesColumn?[    // column to house retrospective Notes or No column if false
    {
      name: "Retrospective Comments",
      options: {
      filter: false,
      sort: false,
      customBodyRender: (customer: CustomerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
        return <CustomerCommentsPanel
        year={year}
        month={month}
        customer={customer}
        hideAuthor={true}
      />
      },    
      setCellProps: () => ({ style: { padding: 5 }}),
      setCellHeaderProps: () => ({ style: { padding: 5 }}),
      }}
  ]:[]),
  ...(planningNotesColumn?[    // column to house retrospective Notes or No column if false
  {
    name: "Planning Comments",
    options: {
    filter: false,
    sort: false,
    customBodyRender: (customer: CustomerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
      return <CustomerPlannigCommentsPanel
      year={year}
      month={month}
      customer={customer}
      hideAuthor={true}
    />
    },    
    setCellProps: () => ({ style: { padding: 5 }}),
    setCellHeaderProps: () => ({ style: { padding: 5 }}),
    }}
]:[]),
  {
    name: "",              // dummy column to house icon
    options: {
      filter: false,
      sort: false,
      download: false,
      viewColumns: false,
      customBodyRender: (customer: CustomerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
        return <IconButton
                  size="small"
                  onClick={(event: any) => {
                    setAnchorEl(event.currentTarget);
                    setPopOverCustomer(customer);
                  }}
                 >
                 <MoreVertIcon fontSize="small" />
               </IconButton>;
      },     
      setCellProps: () => ({ style: { padding: 5 }}),
      setCellHeaderProps: () => ({ style: { padding: 5 }})
      
      }
  }
  ]


  const data = useMemo(() => Object.values(customers).sort((c1, c2) => c1.compare(c2)).map(c => Array(columns.length).fill(c)), [customers]);



  return (
    <Box>
      {/* <Box style={{ display: "flex", justifyContent: "right" }}>
        <FormControlLabel
          control={<Checkbox checked={engagementLengthColumn} />}
          label="Engagement Length"
          onClick={() => setEngagementLengthColumn(!engagementLengthColumn)}
        />
      </Box> */}
      <MUIDataTable
          title={'Customers'}
          columns={columns}
          data={data} 
          options={
                        {
                          filterType: 'multiselect',
                          
                          onDownload: (buildHead, buildBody, columns, data) => {
               return "\uFEFF" + buildHead(columns) + buildBody(
                data.map((it: { index: any; data: any; }) => 
                ({ index: it.index, 
                  data: [
                    
                    it.data[0].name,
                    "",     //warning icon column
                    Math.round(burned?.items[it.data[0].id]?.getConsumedBudget()).toString(),
                    Math.round(burned?.items[it.data[0].id]?.getConsumedOverBudget()).toString(),
                    (burned?.items[it.data[0].id]?.total || budgets?.items[it.data[0].id]?.teamHours + budgets?.items[it.data[0].id]?.opsHours).toFixed(1),
                    (Math.round(
                      ((burned?.items[it.data[0].id]?.harvestConsumedBudget || 0) / burned?.items[it.data[0].id]?.total ||
                        0) * 100
                    ) + "%"),
                    it.data[0].getEngagementLengthInMs() ? (
                      dayjs
                        .duration(
                          it.data[0].getEngagementLengthInMs(),
                          "millisecond"
                        )
                        .humanize()
                    ): kickoffMissingText
                    
                  ] 
                })
                )
                );
            }, 
                          "draggableColumns":{"enabled":true},
                          "pagination":true,
                          "selectableRows":"none",
                          customSearch: (searchQuery, currentRow, columns) => {
                            let isFound = false;
                            let rowText;
                            columns.forEach((col,index) => {
                              switch(col.name){
                                case "Customer":
                                  rowText = currentRow[index].name
                                  if (rowText.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                                    isFound = true;
                                  } 
                                  break;
                                case "Burned Budget":
                                  rowText = Math.round(burned?.items[currentRow[index].id]?.getConsumedInBudget()).toString()
                                  if (rowText.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                                    isFound = true;
                                  } 
                                  break;
                                case "Over Budget":
                                  rowText = Math.round(burned?.items[currentRow[index].id]?.getConsumedOverBudget()).toString()
                                  if (rowText.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                                    isFound = true;
                                  } 
                                  break;
                                case "Budget":
                                  rowText = (burned?.items[currentRow[index].id]?.total || budgets?.items[currentRow[index].id]?.teamHours + budgets?.items[currentRow[index].id]?.opsHours).toFixed(1).toString()
                                  if (rowText.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                                    isFound = true;
                                  } 
                                  break;
                                case "Consumed":
                                  rowText = (Math.round(
                                    ((burned?.items[currentRow[index].id]?.harvestConsumedBudget || 0) / burned?.items[currentRow[index].id]?.total ||
                                      0) * 100
                                  ) + "%")

                                  if (rowText.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                                    isFound = true;
                                  } 
                                  break;
                                case "Engagement Length":
                                  rowText = currentRow[index].getEngagementLengthInMs() ? (
                                    dayjs
                                      .duration(
                                        currentRow[index].getEngagementLengthInMs(),
                                        "millisecond"
                                      )
                                      .humanize()
                                  ) : 'kickoff date is missing'
                                  if (rowText.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                                    isFound = true;
                                  } 
                                  break;
                              }
                            })
                            currentRow.forEach(col => {
                              if (col.toString().indexOf(searchQuery) >= 0) {
                                isFound = true;
                              }
                            });
                            return isFound;
                          },
                        }
          }>
      </MUIDataTable>



      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <CustomerLinkBar
          year={year}
          month={month}
          customer={popOverCustomer}
          comments={false}
        />
      </Popover>
    </Box>
  );
}